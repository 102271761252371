"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CardProductTable = _interopRequireDefault(require("./CardProductTable.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    CardProductTable: _CardProductTable.default
  },
  data: function data() {
    return {};
  },
  methods: {}
};