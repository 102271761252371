"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeType = void 0;
exports.getTimeType = getTimeType;
var _moment = _interopRequireDefault(require("moment"));
var TimeType;
(function (TimeType) {
  TimeType["UpComing"] = "UP_COMING";
  TimeType["Current"] = "CURRENT";
  TimeType["End"] = "END";
})(TimeType || (exports.TimeType = TimeType = {}));
/**
 * Hiện loại sự kiện thời gian so với hiện tại
 * UP_COMING: Sắp bắt đầu
 * CURRENT: Đang diễn ra
 * END: Kết thúc
 * @param {number} start unixTimeStamp
 * @param {number} end unixTimeStamp
 */
function getTimeType(start, end) {
  var current = (0, _moment.default)().unix();
  if (current < start) {
    return TimeType.UpComing;
  }
  if (start <= current && current <= end) {
    return TimeType.Current;
  }
  if (current > end) {
    return TimeType.End;
  }
}