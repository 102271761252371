"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = require("@/utils/enum");
var _DepositMall = _interopRequireDefault(require("./DepositMall.vue"));
var _mall = require("@/types/mall");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    DepositMall: _DepositMall.default
  },
  data: function data() {
    return {
      BalanceType: _enum.BalanceType,
      MallModule: _mall.MallModule
    };
  }
};