var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Mã GD" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_c("span", [_vm._v(_vm._s(row.code))])])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mã GD NCC" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_c("span", [_vm._v(_vm._s(row.otherTransId))])]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.customer
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                              gap: "5px",
                            },
                          },
                          [
                            _c("div", [
                              _c("label", [_vm._v("Tên: ")]),
                              _c("span", [_vm._v(_vm._s(row.customer.name))]),
                            ]),
                            _c("div", [
                              _c("label", [_vm._v("Sđt: ")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.customer.phone || "Chưa có thông tin"
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", [
                              _c("label", [_vm._v("Địa chỉ: ")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.customer.address || "Chưa có thông tin"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thẻ/Sản phẩm" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.cardProduct
                      ? _c("div", [
                          _c("span", [
                            _vm._v(
                              _vm._s("" + row.cardProduct.name) +
                                " - " +
                                _vm._s("" + row.cardProduct.code)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mệnh giá" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      row.amount
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.$formatCurrency(row.amount))),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Lợi nhuận" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$formatCurrency(row.amount - row.paymentAmount)
                          )
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thời điểm giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatDateTime(row.dateCreated))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Phương thức thanh toán",
              align: "center",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.PaymentTypeTrans[row.paymentType]) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", align: "center", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.CardOrderStatusTrans[row.status].color,
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.CardOrderStatusTrans[row.status].label)
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.cardOrderQuery.page,
          limit: _vm.cardOrderQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.cardOrderQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.cardOrderQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }