"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.idealOrderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var idealOrderApi = exports.idealOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/idealOrder',
      params: params
    });
  },
  printed: function printed(idOrder) {
    return (0, _request.default)({
      url: "/idealOrder/".concat(idOrder, "/print"),
      method: 'patch'
    });
  },
  log: function log(id, params) {
    return (0, _request.default)({
      url: "/idealOrder/".concat(id, "/logs"),
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/idealOrder/',
      data: data,
      method: 'post'
    });
  },
  confirm: function confirm(id) {
    return (0, _request.default)({
      url: "/idealOrder/".concat(id, "/confirm"),
      method: 'patch'
    });
  },
  delivered: function delivered(id) {
    return (0, _request.default)({
      url: "/idealOrder/".concat(id, "/delivered"),
      method: 'patch'
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/idealOrder/".concat(id, "/cancel"),
      method: 'delete'
    });
  }
};