"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _format = require("@/utils/format");
//
//
//
//
//
//
//

require("echarts/theme/macarons"); // echarts theme

var animationDuration = 6000;
var _default = exports.default = {
  mixins: [_resize.default],
  props: {
    attrGet: Array,
    pointerName: Array,
    dataChart: Array,
    format: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    barColor: Array
  },
  data: function data() {
    return {
      chart: null
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  mounted: function mounted() {
    // this.initChart();
  },
  methods: {
    initChart: function initChart() {
      var _this = this;
      var self = this;
      this.chart = _echarts.default.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          }
          // formatter: function(params) {
          //   if (self.format == "currency") {
          //     return `Income: ${formatCurrency(parseInt(params[0].value))}`;
          //   }
          //   return `${self.pointerName}: ${params[0].value}`;
          // }
        },
        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: this.dataChart.map(function (item) {
            return item.date;
          }),
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: "value",
          axisTick: {
            show: false
          }
        }],
        series: this.attrGet.map(function (attr, index) {
          return {
            name: _this.pointerName[index],
            type: "bar",
            // stack: "rate",
            // barWidth: "60%",

            itemStyle: {
              color: _this.barColor[index]
            },
            data: _this.dataChart.map(function (item) {
              return _this.$formatNumberVN(item[attr]);
            }),
            animationDuration: animationDuration
          };
        })
      });
    }
  }
};