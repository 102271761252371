"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderFoodStatus = exports.OrderFoodDeliveryByTrans = exports.OrderFoodDeliveryBy = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var OrderFoodStatus;
(function (OrderFoodStatus) {
  OrderFoodStatus["PendingPayment"] = "PENDING_PAYMENT";
  OrderFoodStatus["Waiting"] = "WAITING";
  OrderFoodStatus["StoreConfirming"] = "STORE_CONFIRMING";
  OrderFoodStatus["StoreCooking"] = "STORE_COOKING";
  OrderFoodStatus["StoreCooked"] = "STORE_COOKED";
  OrderFoodStatus["Schedule"] = "SCHEDULE";
  OrderFoodStatus["FindDriver"] = "FIND_DRIVER";
  OrderFoodStatus["AcceptOrder"] = "ACCEPT_ORDER";
  OrderFoodStatus["Run"] = "RUN";
  OrderFoodStatus["Pay"] = "PAY";
  OrderFoodStatus["Complete"] = "COMPLETE";
  OrderFoodStatus["CustomerCancel"] = "CUSTOMER_CANCEL";
  OrderFoodStatus["DriverCancel"] = "DRIVER_CANCEL";
  OrderFoodStatus["StaffCancel"] = "STAFF_CANCEL";
  OrderFoodStatus["StoreCancel"] = "STORE_CANCEL";
})(OrderFoodStatus || (exports.OrderFoodStatus = OrderFoodStatus = {}));
var OrderFoodDeliveryBy;
(function (OrderFoodDeliveryBy) {
  OrderFoodDeliveryBy["Customer"] = "CUSTOMER";
  OrderFoodDeliveryBy["Driver"] = "DRIVER";
})(OrderFoodDeliveryBy || (exports.OrderFoodDeliveryBy = OrderFoodDeliveryBy = {}));
var OrderFoodDeliveryByTrans = exports.OrderFoodDeliveryByTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, OrderFoodDeliveryBy.Customer, 'Khách tự lấy'), OrderFoodDeliveryBy.Driver, 'Tài xế lấy');