"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cardOrderApi = void 0;
var _request = require("@/utils/request");
var cardOrderApi = exports.cardOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/cardOrder',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/cardOrder',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/cardOrder/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/cardOrder/".concat(id),
      method: 'delete'
    });
  },
  summaryStatus: function summaryStatus(params) {
    return (0, _request.request)({
      url: '/cardOrder/summary/status',
      params: params
    });
  },
  refund: function refund(id) {
    return (0, _request.request)({
      url: "/cardOrder/".concat(id, "/refund"),
      method: 'delete'
    });
  }
};