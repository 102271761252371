"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmployerTransactionTypeTransform = exports.EmployerTransactionType = exports.EmployerStatus = exports.EmployerDepositStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var EmployerDepositStatus;
(function (EmployerDepositStatus) {
  EmployerDepositStatus["Pending"] = "PENDING";
  EmployerDepositStatus["Fail"] = "FAIL";
  EmployerDepositStatus["Complete"] = "COMPLETE";
})(EmployerDepositStatus || (exports.EmployerDepositStatus = EmployerDepositStatus = {}));
var EmployerTransactionType;
(function (EmployerTransactionType) {
  EmployerTransactionType["Deposit"] = "DEPOSIT";
  EmployerTransactionType["Commission"] = "COMMISSION";
})(EmployerTransactionType || (exports.EmployerTransactionType = EmployerTransactionType = {}));
var EmployerTransactionTypeTransform = exports.EmployerTransactionTypeTransform = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, EmployerTransactionType.Commission, 'Trừ chiết khấu'), EmployerTransactionType.Deposit, 'Nạp tiền');
var EmployerStatus;
(function (EmployerStatus) {
  EmployerStatus["Pending"] = "PENDING";
  EmployerStatus["Approve"] = "APPROVE";
  EmployerStatus["Reject"] = "REJECT";
})(EmployerStatus || (exports.EmployerStatus = EmployerStatus = {}));