"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ggMapApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var ggMapApi = exports.ggMapApi = {
  findByQuery: function findByQuery(text) {
    return (0, _request.default)({
      url: '/ggMap/findByQuery',
      params: {
        text: text
      }
    });
  },
  findByPlaceId: function findByPlaceId(place_id) {
    return (0, _request.default)({
      url: '/ggMap/getPlaceDetail',
      params: {
        place_id: place_id
      }
    });
  }
};