"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unixToDate = unixToDate;
var _moment = _interopRequireDefault(require("moment"));
function unixToDate(unix) {
  if (unix > 0) {
    return _moment.default.unix(unix).format('DD/MM/YYY');
  } else {
    return '--';
  }
}