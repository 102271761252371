"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _StackBarChart = _interopRequireDefault(require("../StackBarChart.vue"));
var _moment = _interopRequireDefault(require("moment"));
var _cardOrderDashboard = require("@/api/cardOrderDashboard.api");
var _cardOrder = require("@/types/cardOrder");
var _default = exports.default = {
  components: {
    BarChart: _StackBarChart.default
  },
  props: ["listQuery", "type"],
  watch: {
    date: function date(newVal) {
      this.getData();
    },
    status: function status(newVal) {
      this.getData();
    },
    listQuery: {
      handler: function handler(val) {
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: "Tuần trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "Tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "3 tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
      CardOrderType: _cardOrder.CardOrderType,
      date: [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      chartData: [],
      status: "",
      STATUS_ORDER: {
        COMPLETE: "Đã hoàn thành",
        CUSTOMER_CANCEL: "Người Dùng hủy chuyến",
        DRIVER_CANCEL: "Tài xế hủy chuyến"
      }
      // tooltip: {
      //     trigger: "axis",
      //     axisPointer: {
      //       // 坐标轴指示器，坐标轴触发有效
      //       type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      //     },
      //     formatter: function (params) {
      //       if (self.format == "currency") {
      //         return `Income: ${formatCurrency(parseInt(params[0].value))}`;
      //       }
      //       return `${self.pointerName}: ${params[0].value}`;
      //     },
      //   }
    };
  },
  created: function created() {
    // this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        fromAt: this.date ? (0, _moment.default)(this.date[0]).startOf("day").unix() : undefined,
        toAt: this.date ? (0, _moment.default)(this.date[1]).endOf("day").unix() : undefined,
        status: this.status,
        type: this.type
      });
      _cardOrderDashboard.cardOrderDashboardApi.summary(data).then(function (res) {
        var data = res.data;
        _this.chartData = data.map(function (item) {
          return {
            amount: item.amount,
            // money: 1100000,
            date: item.d,
            profit: item.profit
          };
        });
        console.log("chart data", _this.chartData);
        _this.$nextTick().then(function () {
          _this.$refs["chart"].initChart();
        });
      });
    }
  }
};