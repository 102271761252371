"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = require("@/utils/enum");
var _DepositCustomer = _interopRequireDefault(require("./DepositCustomer.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    DepositCustomer: _DepositCustomer.default
  },
  data: function data() {
    return {
      BalanceType: _enum.BalanceType
    };
  }
};