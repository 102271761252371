"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configRewardSaleApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var configRewardSaleApi = exports.configRewardSaleApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/configRewardSale",
      params: params
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/configRewardSale/".concat(id),
      method: 'patch',
      data: data
    });
  }
};