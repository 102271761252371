import { render, staticRenderFns } from "./SchoolAdmissionTable.vue?vue&type=template&id=54aecfe4"
import script from "./SchoolAdmissionTable.vue?vue&type=script&lang=ts"
export * from "./SchoolAdmissionTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\028fclass_admintool\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54aecfe4')) {
      api.createRecord('54aecfe4', component.options)
    } else {
      api.reload('54aecfe4', component.options)
    }
    module.hot.accept("./SchoolAdmissionTable.vue?vue&type=template&id=54aecfe4", function () {
      api.rerender('54aecfe4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/school-admission/SchoolAdmissionTable.vue"
export default component.exports