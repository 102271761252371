"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _enum = require("@/utils/enum");
var order = _interopRequireWildcard(require("@/api/order"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      dialogVisible: false,
      formData: {},
      STATUS_ORDER: _enum.STATUS_ORDER,
      btnLoading: false
    };
  },
  methods: {
    handleUpdate: function handleUpdate(order) {
      this.dialogVisible = true;
      this.formData = (0, _objectSpread2.default)({}, order);
    },
    updateData: function updateData() {
      var _this = this;
      var data = {
        status: this.formData.status
      };
      this.btnLoading = true;
      order.update(this.formData.id, data).then(function (res) {
        _this.$notify({
          type: "success",
          title: "Cập nhật thành công"
        });
        _this.$emit("update:success");
        _this.dialogVisible = false;
      }).finally(function () {
        _this.btnLoading = false;
      });
    }
  }
};