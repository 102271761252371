"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMember = addMember;
exports.companyApi = void 0;
exports.create = create;
exports.createVehicle = createVehicle;
exports.getList = getList;
exports.getListMembers = getListMembers;
exports.getListRefCustomer = getListRefCustomer;
exports.getListRefDriver = getListRefDriver;
exports.getListRefMall = getListRefMall;
exports.getListRefStore = getListRefStore;
exports.getVehicle = getVehicle;
exports.remove = remove;
exports.removeMember = removeMember;
exports.transaction = transaction;
exports.update = update;
exports.updateMember = updateMember;
exports.updateRef = updateRef;
var _request = _interopRequireDefault(require("@/utils/request"));
var companyApi = exports.companyApi = {
  getList: getList,
  create: create,
  getVehicle: getVehicle,
  createVehicle: createVehicle,
  transaction: transaction,
  update: update,
  addMember: addMember,
  removeMember: removeMember,
  getListMembers: getListMembers,
  updateRef: updateRef,
  remove: remove,
  getListRefCustomer: getListRefCustomer,
  getListRefDriver: getListRefDriver,
  getListRefStore: getListRefStore,
  getListRefMall: getListRefMall
};
function getList(params) {
  return (0, _request.default)({
    url: "/company",
    method: "get",
    params: params
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/company",
    method: "post",
    data: data
  });
}
function getVehicle(companyId) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/ref/vehicle")
  });
}
function createVehicle(companyId, data) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/ref/vehicle"),
    method: "post",
    data: data
  });
}
function transaction(companyId, params) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/transaction"),
    params: params
  });
}
function update(companyId, data) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/update"),
    method: "post",
    data: data
  });
}
function addMember(companyId, data) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/member/add"),
    method: "post",
    data: data
  });
}
function removeMember(companyId, data) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/member/remove"),
    method: "post",
    data: data
  });
}
function getListMembers(companyId, params) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/members"),
    params: params
  });
}
function updateMember(companyMemberId, data) {
  return (0, _request.default)({
    url: "/companyMember/".concat(companyMemberId, "/update"),
    method: "post",
    data: data
  });
}
function updateRef(companyId, data) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/ref"),
    method: "post",
    data: data
  });
}
function remove(companyId) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/delete"),
    method: "post"
  });
}
function getListRefCustomer(companyId, params) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/ref-history/customer"),
    params: params
  });
}
function getListRefDriver(companyId, params) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/ref-history/driver"),
    params: params
  });
}
function getListRefStore(companyId, params) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/ref-history/store"),
    params: params
  });
}
function getListRefMall(companyId, params) {
  return (0, _request.default)({
    url: "/company/".concat(companyId, "/ref-history/mall"),
    params: params
  });
}