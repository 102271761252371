"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BookingRoomConditionType = exports.BookingRoomBedTypeTrans = exports.BookingRoomBedType = exports.BookingPolicyTypeTrans = exports.BookingPolicyType = exports.BookingPaymentType = exports.BookingOrderStatus = exports.BookingOrderPurposeTrans = exports.BookingOrderPurpose = exports.BookingLocationUnit = exports.BookingHotelStatus = exports.BookingHostStatus = exports.BookingFeeAppType = exports.BookingDiscountTypeTrans = exports.BookingDiscountType = exports.BookingBusinessTypeTrans = exports.BookingBusinessType = exports.BookingBannerLayoutTrans = exports.BookingBannerLayout = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var BookingLocationUnit;
(function (BookingLocationUnit) {
  BookingLocationUnit["km"] = "KM";
  BookingLocationUnit["m"] = "M";
})(BookingLocationUnit || (exports.BookingLocationUnit = BookingLocationUnit = {}));
var BookingRoomConditionType;
(function (BookingRoomConditionType) {
  BookingRoomConditionType["No"] = "NO";
  BookingRoomConditionType["Paid"] = "PAID";
  BookingRoomConditionType["Custom"] = "CUSTOM";
})(BookingRoomConditionType || (exports.BookingRoomConditionType = BookingRoomConditionType = {}));
var BookingBusinessType;
(function (BookingBusinessType) {
  BookingBusinessType["Hotel"] = "HOTEL";
  BookingBusinessType["HolidayInn"] = "HOLIDAY_INN";
  BookingBusinessType["Resort"] = "RESORT";
})(BookingBusinessType || (exports.BookingBusinessType = BookingBusinessType = {}));
var BookingBusinessTypeTrans = exports.BookingBusinessTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookingBusinessType.Hotel, "Khách sạn"), BookingBusinessType.HolidayInn, "Holiday Inn"), BookingBusinessType.Resort, "Resort");
var BookingHostStatus;
(function (BookingHostStatus) {
  BookingHostStatus["Pending"] = "PENDING";
  BookingHostStatus["Approve"] = "APPROVE";
  BookingHostStatus["Complete"] = "COMPLETE";
  BookingHostStatus["Reject"] = "REJECT";
})(BookingHostStatus || (exports.BookingHostStatus = BookingHostStatus = {}));
var BookingDiscountType;
(function (BookingDiscountType) {
  BookingDiscountType["Amount"] = "AMOUNT";
  BookingDiscountType["Percent"] = "PERCENT"; //theo %
})(BookingDiscountType || (exports.BookingDiscountType = BookingDiscountType = {}));
var BookingDiscountTypeTrans = exports.BookingDiscountTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookingDiscountType.Amount, 'Giảm theo số tiền cứng'), BookingDiscountType.Percent, 'Giảm theo phần trăm');
var BookingOrderPurpose;
(function (BookingOrderPurpose) {
  BookingOrderPurpose["Travel"] = "TRAVEL";
  BookingOrderPurpose["Work"] = "WORK"; //công tác
})(BookingOrderPurpose || (exports.BookingOrderPurpose = BookingOrderPurpose = {}));
var BookingOrderPurposeTrans = exports.BookingOrderPurposeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookingOrderPurpose.Travel, 'Du lịch'), BookingOrderPurpose.Work, 'Công tác');
var BookingPaymentType;
(function (BookingPaymentType) {
  BookingPaymentType["Momo"] = "MOMO";
  BookingPaymentType["VnPay"] = "VNPAY";
  BookingPaymentType["Cash"] = "CASH";
})(BookingPaymentType || (exports.BookingPaymentType = BookingPaymentType = {}));
var BookingBannerLayout;
(function (BookingBannerLayout) {
  BookingBannerLayout["Top"] = "TOP";
  BookingBannerLayout["Mid"] = "MID";
  BookingBannerLayout["Bottom"] = "BOTTOM";
})(BookingBannerLayout || (exports.BookingBannerLayout = BookingBannerLayout = {}));
var BookingBannerLayoutTrans = exports.BookingBannerLayoutTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookingBannerLayout.Top, 'Đầu trang'), BookingBannerLayout.Mid, 'Giữa trang'), BookingBannerLayout.Bottom, 'Cuối trang');
var BookingOrderStatus;
(function (BookingOrderStatus) {
  BookingOrderStatus["Pending"] = "PENDING";
  BookingOrderStatus["Cancel"] = "CANCEL";
  BookingOrderStatus["Confirm"] = "CONFIRM";
  BookingOrderStatus["RequestCheckout"] = "REQUEST_CHECKOUT";
  BookingOrderStatus["Checkout"] = "CHECKOUT";
  BookingOrderStatus["Complete"] = "COMPLETE";
})(BookingOrderStatus || (exports.BookingOrderStatus = BookingOrderStatus = {}));
var BookingPolicyType;
(function (BookingPolicyType) {
  BookingPolicyType["Cancel"] = "CANCEL";
  BookingPolicyType["Safe"] = "SAFE";
  BookingPolicyType["Regular"] = "REGULAR";
  BookingPolicyType["PreconditionBooking"] = "PRECONDITION_BOOKING";
})(BookingPolicyType || (exports.BookingPolicyType = BookingPolicyType = {}));
var BookingPolicyTypeTrans = exports.BookingPolicyTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookingPolicyType.Cancel, 'Chính sách hủy phòng'), BookingPolicyType.Safe, 'An toàn'), BookingPolicyType.Regular, 'Quy định'), BookingPolicyType.PreconditionBooking, 'Điều kiện đặt phòng');
var BookingRoomBedType;
(function (BookingRoomBedType) {
  BookingRoomBedType["Single"] = "SINGLE";
  BookingRoomBedType["Double"] = "DOUBLE";
})(BookingRoomBedType || (exports.BookingRoomBedType = BookingRoomBedType = {}));
var BookingRoomBedTypeTrans = exports.BookingRoomBedTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, BookingRoomBedType.Single, 'Giường đơn'), BookingRoomBedType.Double, 'Giường đôi');
var BookingHotelStatus;
(function (BookingHotelStatus) {
  BookingHotelStatus["Pending"] = "PENDING";
  BookingHotelStatus["Approve"] = "APPROVE";
  BookingHotelStatus["Reject"] = "REJECT";
})(BookingHotelStatus || (exports.BookingHotelStatus = BookingHotelStatus = {}));
var BookingFeeAppType;
(function (BookingFeeAppType) {
  BookingFeeAppType["Fixed"] = "FIXED";
  BookingFeeAppType["Percent"] = "PERCENT";
})(BookingFeeAppType || (exports.BookingFeeAppType = BookingFeeAppType = {}));