import { render, staticRenderFns } from "./SchoolTagModal.vue?vue&type=template&id=36ef3a4c"
import script from "./SchoolTagModal.vue?vue&type=script&lang=ts"
export * from "./SchoolTagModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\028fclass_admintool\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36ef3a4c')) {
      api.createRecord('36ef3a4c', component.options)
    } else {
      api.reload('36ef3a4c', component.options)
    }
    module.hot.accept("./SchoolTagModal.vue?vue&type=template&id=36ef3a4c", function () {
      api.rerender('36ef3a4c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/school-tag/components/SchoolTagModal.vue"
export default component.exports