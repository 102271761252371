"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WheelTypeTrans = exports.WheelType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var VehicleType;
(function (VehicleType) {
  VehicleType["Car"] = "CAR";
  VehicleType["Bike"] = "BIKE";
  VehicleType["Taxi"] = "TAXI";
  VehicleType["Delivery"] = "DELIVERY";
  VehicleType["DeliveryFood"] = "DELIVERY_FOOD";
  VehicleType["Mall"] = "MALL";
  VehicleType["Tram"] = "TRAM";
  VehicleType["Tricycles"] = "TRICYCLES";
})(VehicleType || (VehicleType = {}));
var WheelType;
(function (WheelType) {
  WheelType["TwoWheel"] = "TWO_WHEEL";
  WheelType["FourWheel"] = "FOUR_WHEEL"; //4 bánh
})(WheelType || (exports.WheelType = WheelType = {}));
var WheelTypeTrans = exports.WheelTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, WheelType.TwoWheel, '2 bánh'), WheelType.FourWheel, '4 bánh');