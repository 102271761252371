var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                attrs: { placeholder: "Tìm tiêu đề" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getList($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Ngày")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "To",
                  "start-placeholder": "Start date",
                  "end-placeholder": "End date",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c("address-filter", {
            attrs: {
              query: _vm.listQuery,
              visibleWard: false,
              visibleDistrict: false,
            },
            on: { "change:address": _vm.getList },
          }),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái")]),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "Tất cả" },
                  on: { change: _vm.getList },
                  model: {
                    value: _vm.listQuery.isShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isShow", $$v)
                    },
                    expression: "listQuery.isShow",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _c("el-option", { attrs: { label: "Ẩn", value: false } }),
                  _c("el-option", { attrs: { label: "Hiện", value: true } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Ví trí")]),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "Tất cả" },
                  on: { change: _vm.getList },
                  model: {
                    value: _vm.listQuery.layout,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "layout", $$v)
                    },
                    expression: "listQuery.layout",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _c("el-option", {
                    attrs: { label: "Đầu trang", value: "TOP" },
                  }),
                  _c("el-option", {
                    attrs: { label: "Cuối trang", value: "BOTTOM" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v(" Thêm Banner ")]
          ),
          _vm.checkPermissionExport
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    loading: _vm.loadingExport,
                  },
                  on: { click: _vm.handleExportExcel },
                },
                [_vm._v(" Xuất excel ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Tiêu đề" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.thumbnail
                      ? _c("img", {
                          staticStyle: { "border-radius": "8px" },
                          attrs: {
                            src: _vm.$url.image(row.thumbnail),
                            width: "150",
                            alt: "",
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center",
                              "flex-direction": "column",
                              "margin-top": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-video-play",
                              staticStyle: { "font-size": "30px" },
                            }),
                            _c("span", [_vm._v("Video")]),
                          ]
                        ),
                    _c("br"),
                    _c("label", { domProps: { innerHTML: _vm._s(row.title) } }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thành phố", prop: "city.nameWithType" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(row.city ? row.city.nameWithType : "Tất cả")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Nội dung" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" Xem chi tiết ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thứ tự hiển thị", prop: "position" },
          }),
          _c("el-table-column", {
            attrs: { label: "Vị trí" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return row.layout
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(_vm.bannerLayoutTrans[row.layout].title) +
                              " "
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "230" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    !row.isShow
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShow(row)
                              },
                            },
                          },
                          [_vm._v(" Hiện ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleHide(row)
                              },
                            },
                          },
                          [_vm._v(" Ẩn ")]
                        ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v(" Xóa ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update" ? "Cập nhật banner" : "Thêm banner",
            visible: _vm.dialogVisible,
            width: "1200px",
            fullscreen: "",
            top: "15px",
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tiêu đề",
                                        prop: "title",
                                      },
                                    },
                                    [
                                      _c("Tinymce", {
                                        ref: "titleEditor",
                                        attrs: {
                                          value: _vm.formData.title,
                                          width: "100%",
                                          height: 50,
                                          lite: true,
                                          menubar: "",
                                          visibleUpload: false,
                                        },
                                        on: { onInit: _vm.handleTitleInit },
                                        model: {
                                          value: _vm.formData.title,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "title", $$v)
                                          },
                                          expression: "formData.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Thành phố",
                                        prop: "cityId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { filterable: "" },
                                          model: {
                                            value: _vm.formData.cityId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "cityId",
                                                $$v
                                              )
                                            },
                                            expression: "formData.cityId",
                                          },
                                        },
                                        [
                                          !_vm.$store.state.user.info.branch
                                            ? _c("el-option", {
                                                attrs: {
                                                  label: "Tất cả",
                                                  value: 0,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._l(_vm.cities, function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.nameWithType,
                                                value: item.id,
                                              },
                                            })
                                          }),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Vị trí",
                                        prop: "layout",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { filterable: "" },
                                          model: {
                                            value: _vm.formData.layout,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "layout",
                                                $$v
                                              )
                                            },
                                            expression: "formData.layout",
                                          },
                                        },
                                        _vm._l(
                                          _vm.bannerLayoutTrans,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.title,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Thứ tự hiển thị",
                                        prop: "position",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.position,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "position",
                                              $$v
                                            )
                                          },
                                          expression: "formData.position",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      staticStyle: { "margin-bottom": "1em" },
                                      attrs: { indeterminate: false },
                                      model: {
                                        value: _vm.isVideoUpload,
                                        callback: function ($$v) {
                                          _vm.isVideoUpload = $$v
                                        },
                                        expression: "isVideoUpload",
                                      },
                                    },
                                    [_c("b", [_vm._v("Tải lên video")])]
                                  ),
                                ],
                                1
                              ),
                              !_vm.isVideoUpload
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label:
                                              _vm.formData.layout ==
                                              _vm.BannerLayout.Bottom
                                                ? "Hình ảnh (Tỷ lệ: 428/250, khuyến nghị: 428px x 250px, dung lượng tối đa 1MB"
                                                : "Hình ảnh (Tỷ lệ: 428/400, khuyến nghị: 428px x 400px, dung lượng tối đa 1MB",
                                            prop: "thumbnail",
                                          },
                                        },
                                        [
                                          _c("SingleImageS3", {
                                            attrs: {
                                              pathImage: _vm.formData.thumbnail,
                                              width: 360,
                                              sizeLimit: 1,
                                              height: 144,
                                              prefixFile:
                                                _vm.PrefixImage.Banner,
                                            },
                                            on: {
                                              "upload:success":
                                                _vm.handleUploadSuccess,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { span: 24, offset: 0 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label:
                                              "Video (Tỷ lệ 20/8, khuyến nghị: 1200x480)",
                                            prop: "videoUrl",
                                          },
                                        },
                                        [
                                          _c("UploadVideoS3", {
                                            attrs: {
                                              videoUrl: _vm.formData.videoUrl,
                                              width: 400,
                                              height: 200,
                                            },
                                            on: {
                                              "upload:success": _vm.setVideo,
                                              delete: _vm.handleDeleteVideo,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: _vm.bannerMode.Post },
                                  model: {
                                    value: _vm.formData.mode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "mode", $$v)
                                    },
                                    expression: "formData.mode",
                                  },
                                },
                                [_vm._v("Dạng bài viết")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: _vm.bannerMode.Link },
                                  model: {
                                    value: _vm.formData.mode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "mode", $$v)
                                    },
                                    expression: "formData.mode",
                                  },
                                },
                                [_vm._v("Dạng liên kết ngoài")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: _vm.bannerMode.IdealProduct },
                                  model: {
                                    value: _vm.formData.mode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "mode", $$v)
                                    },
                                    expression: "formData.mode",
                                  },
                                },
                                [_vm._v("Liên kết đến chiến dịch bán hàng")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: _vm.bannerMode.IdealPage },
                                  model: {
                                    value: _vm.formData.mode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "mode", $$v)
                                    },
                                    expression: "formData.mode",
                                  },
                                },
                                [_vm._v("Liên kết đến trang Fclass Draws")]
                              ),
                            ],
                            1
                          ),
                          [_vm.bannerMode.Link, _vm.bannerMode.Post].includes(
                            _vm.formData.mode
                          )
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "Mô tả", prop: "body" } },
                                [
                                  _vm.formData.mode == _vm.bannerMode.Link
                                    ? _c("el-input", {
                                        model: {
                                          value: _vm.formData.link,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "link", $$v)
                                          },
                                          expression: "formData.link",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.formData.mode == _vm.bannerMode.Post
                                    ? _c("Tinymce", {
                                        ref: "editor",
                                        attrs: { width: "100%", height: 400 },
                                        on: { onInit: _vm.handleContentInit },
                                        model: {
                                          value: _vm.formData.body,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "body", $$v)
                                          },
                                          expression: "formData.body",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.formData.mode == _vm.bannerMode.IdealProduct
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Chọn một chiến dịch bán hàng",
                                    prop: "idealProductId",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: true,
                                        filterable: "",
                                        remote: "",
                                        "remote-method": _vm.searchIdealProduct,
                                      },
                                      model: {
                                        value: _vm.formData.idealProductId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "idealProductId",
                                            $$v
                                          )
                                        },
                                        expression: "formData.idealProductId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.idealProducts,
                                      function (item, index) {
                                        return _c(
                                          "el-option",
                                          {
                                            key: index,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: { float: "left" },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  float: "right",
                                                  color: "#8492a6",
                                                  "font-size": "13px",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.code))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }