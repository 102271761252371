"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bannerLayoutTrans = exports.IdealBannerLayout = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var IdealBannerLayout;
(function (IdealBannerLayout) {
  IdealBannerLayout["Top"] = "TOP";
  IdealBannerLayout["Middle"] = "MIDDLE";
  IdealBannerLayout["Bottom"] = "BOTTOM";
})(IdealBannerLayout || (exports.IdealBannerLayout = IdealBannerLayout = {}));
var bannerLayoutTrans = exports.bannerLayoutTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, IdealBannerLayout.Top, {
  title: "Đầu trang",
  value: IdealBannerLayout.Top
}), IdealBannerLayout.Middle, {
  title: "Giữa trang",
  value: IdealBannerLayout.Middle
}), IdealBannerLayout.Bottom, {
  title: "Cuối trang",
  value: IdealBannerLayout.Bottom
});