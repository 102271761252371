"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CardOrderType = exports.CardOrderStatusTrans = exports.CardOrderStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var CardOrderType;
(function (CardOrderType) {
  CardOrderType["Card"] = "CARD";
  CardOrderType["Topup"] = "TOPUP";
  CardOrderType["Bill"] = "BILL";
  CardOrderType["CardData"] = "CARD_DATA";
  CardOrderType["TopupData"] = "TOPUP_DATA";
})(CardOrderType || (exports.CardOrderType = CardOrderType = {}));
var CardOrderStatus;
(function (CardOrderStatus) {
  CardOrderStatus["PendingPayment"] = "PENDING_PAYMENT";
  CardOrderStatus["Pending"] = "PENDING";
  CardOrderStatus["Complete"] = "COMPLETE";
  CardOrderStatus["Fail"] = "FAIL";
  CardOrderStatus["Refund"] = "REFUND";
})(CardOrderStatus || (exports.CardOrderStatus = CardOrderStatus = {}));
var CardOrderStatusTrans = exports.CardOrderStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, CardOrderStatus.PendingPayment, {
  label: "Chờ thanh toán",
  color: 'info',
  value: CardOrderStatus.Pending
}), CardOrderStatus.Pending, {
  label: "Đang xử lý",
  color: 'primary',
  value: CardOrderStatus.Pending
}), CardOrderStatus.Complete, {
  label: "Hoàn tất",
  color: 'success',
  value: CardOrderStatus.Complete
}), CardOrderStatus.Fail, {
  label: "Thất bại",
  color: 'danger',
  value: CardOrderStatus.Fail
}), CardOrderStatus.Refund, {
  label: "Hoàn tiền",
  color: 'warning',
  value: CardOrderStatus.Refund
});