"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.deleteTip = deleteTip;
exports.get = get;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function get() {
  return (0, _request.default)({
    url: '/tips',
    method: 'get'
  });
}
function create(data) {
  return (0, _request.default)({
    url: '/tips',
    method: 'post',
    data: data
  });
}
function update(tipId, data) {
  return (0, _request.default)({
    url: "/tips/".concat(tipId, "/update"),
    method: 'post',
    data: data
  });
}
function deleteTip(tipId) {
  return (0, _request.default)({
    url: "/tips/".concat(tipId, "/delete"),
    method: 'post'
  });
}