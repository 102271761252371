"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merchandiseApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var merchandiseApi = exports.merchandiseApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/merchandise',
      params: params
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/merchandise/".concat(id, "/update"),
      method: 'post',
      data: data
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/merchandise',
      method: 'post',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/merchandise/".concat(id, "/delete"),
      method: 'post'
    });
  }
};