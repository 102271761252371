"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MerchantNotificationTypeTrans = exports.MerchantNotificationType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MerchantNotificationType;
(function (MerchantNotificationType) {
  MerchantNotificationType["News"] = "NEWS";
  MerchantNotificationType["Order"] = "ORDER";
  //Open screen on mobile
  MerchantNotificationType["OpenShareScreen"] = "OPEN_SHARE_SCREEN";
  MerchantNotificationType["OpenApp"] = "OPEN_APP";
  MerchantNotificationType["OpenNews"] = "OPEN_NEWS";
  MerchantNotificationType["OpenMenu"] = "OPEN_MENU";
  MerchantNotificationType["OpenPromotion"] = "OPEN_PROMOTION";
  MerchantNotificationType["OpenProfile"] = "OPEN_ACCOUNT";
})(MerchantNotificationType || (exports.MerchantNotificationType = MerchantNotificationType = {}));
var MerchantNotificationTypeTrans = exports.MerchantNotificationTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MerchantNotificationType.News, 'Tin tức'), MerchantNotificationType.OpenShareScreen, 'Trang share'), MerchantNotificationType.OpenApp, 'Trang chủ app merchant'), MerchantNotificationType.OpenNews, 'Trang tin tức'), MerchantNotificationType.OpenMenu, 'Trang danh sách menu quán'), MerchantNotificationType.OpenPromotion, 'Trang khuyến mãi'), MerchantNotificationType.OpenProfile, 'Trang profile');