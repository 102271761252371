"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.idealBannerApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var idealBannerApi = exports.idealBannerApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/idealBanner',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/idealBanner/',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/idealBanner/".concat(id),
      data: data,
      method: 'patch'
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/idealBanner/".concat(id),
      method: 'delete'
    });
  }
};