"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.object.to-string.js");
var _format = require("@/utils/format");
var _date = require("@/utils/date");
var _enum = require("@/utils/enum");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = require("@/views/order/index.vue");
var _orderDelivery = require("@/api/order-delivery");
var _DeliveryDetailDialog = _interopRequireDefault(require("@/views/order-delivery/components/DeliveryDetailDialog.vue"));
var _ImagesOrder = _interopRequireDefault(require("@/views/order-delivery/components/ImagesOrder.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["driverId"],
  components: {
    Pagination: _Pagination.default,
    DeliveryDetailDialog: _DeliveryDetailDialog.default,
    ImagesOrder: _ImagesOrder.default
  },
  data: function data() {
    return {
      STATUS_ORDER: _enum.STATUS_ORDER,
      tagOptions: {
        ACCEPT_ORDER: {
          type: "warning",
          effect: "plain",
          color: "yellow"
        },
        FIND_DRIVER: {
          type: "warning",
          effect: "plain",
          color: "yellow"
        },
        PICK_UP: {
          type: "",
          effect: "plain",
          color: "primary"
        },
        RUN: {
          type: "",
          effect: "dark",
          color: "primary"
        },
        PAY: {
          type: "success",
          effect: "plain",
          color: "green"
        },
        COMPLETE: {
          type: "success",
          effect: "plain",
          color: "green"
        },
        CUSTOMER_CANCEL: {
          type: "danger",
          effect: "plain",
          color: "red"
        },
        DRIVER_CANCEL: {
          type: "danger",
          effect: "dark",
          color: "red"
        },
        STAFF_CANCEL: {
          type: "danger",
          effect: "dark",
          color: "red"
        },
        STORE_CANCEL: {
          type: "danger",
          effect: "dark",
          color: "red"
        },
        PENDING_PAYMENT: {
          type: "warning",
          effect: "plain",
          color: "yellow"
        }
      },
      tableData: [],
      listQuery: {
        page: 1,
        limit: 50
      },
      total: 0,
      tableLoading: false,
      PaymentStatus: _index.PaymentStatus
    };
  },
  watch: {
    customerId: {
      handler: function handler() {
        this.listQuery.page = 1;
        this.getTableData();
      },
      immediate: true
    }
  },
  methods: {
    formatDateTime: function formatDateTime(n) {
      return (0, _date.formatDateTime)(n);
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    getTableData: function getTableData() {
      var _this = this;
      this.tableLoading = true;
      this.listQuery.driverId = this.driverId;
      _orderDelivery.orderDeliveryApi.findAll(this.listQuery).then(function (res) {
        _this.tableData = res.data.data.map(function (item) {
          if (item.paymentBy == "RECEIVER") {
            item.distance = item.orderDeliveryDetails.reduce(function (prev, cur) {
              return prev + cur.distance;
            }, 0);
          }
          return item;
        });
        _this.total = res.data.total;
      }).finally(function () {
        _this.tableLoading = false;
      });
    }
  }
};