"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var company = _interopRequireWildcard(require("@/api/company"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      dialogLoading: false,
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      submitLoading: false,
      formData: {}
    };
  },
  methods: {
    handleUpdate: function handleUpdate(data) {
      var _this = this;
      this.dialogVisible = true;
      this.dialogStatus = "edit";
      this.$nextTick(function () {
        _this.$refs["form"].clearValidate();
        _this.formData = (0, _objectSpread2.default)({}, data);
      });
    },
    updateData: function updateData() {
      var _this2 = this;
      this.submitLoading = true;
      var data = {
        refCode: this.formData.refCode.toUpperCase(),
        rewardRef: this.formData.rewardRef
      };
      company.updateRef(this.formData.id, data).then(function () {
        _this2.$message({
          type: "success",
          message: "Cập nhật mã giới thiệu thành công"
        });
        _this2.dialogVisible = false;
        _this2.$emit("update:success");
      }).finally(function () {
        return _this2.submitLoading = false;
      });
    }
  }
};