"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.number.to-fixed.js");
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _PieChart = _interopRequireDefault(require("../PieChart"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
var _moment = _interopRequireDefault(require("moment"));
var vehicleApi = _interopRequireWildcard(require("@/api/vehicle"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    PieChart: _PieChart.default
  },
  props: ["listQuery"],
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: "Tuần trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "Tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "3 tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
      date: [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      dataChart: [],
      series: []
    };
  },
  watch: {
    date: function date(newVal) {
      this.getData();
    },
    listQuery: {
      handler: function handler(val) {
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    // this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        from: this.date[0],
        to: this.date[1]
      });
      dashboard.saleVehicle(data).then(function (res) {
        var sum = res.data.report.reduce(function (sum, element) {
          return sum + element.total;
        }, 0);
        _this.dataChart = res.data.report.map(function (e) {
          return [e.vehicleName, parseFloat((e.total / sum * 100).toFixed(2))];
        });
        _this.$nextTick().then(function () {
          _this.$refs["chart"].initChart();
        });
      });
    }
  }
};