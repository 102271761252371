"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.operateApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var operateApi = exports.operateApi = {
  driverFree: function driverFree(params) {
    return (0, _request.default)({
      url: "/operation/driver/free",
      params: params
    });
  },
  driverGpsById: function driverGpsById(driverId) {
    return (0, _request.default)({
      url: "/operation/driver/gps",
      params: {
        driverId: driverId
      }
    });
  }
};