"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IdealNotificationTypeTrans = exports.IdealNotificationType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var IdealNotificationType;
(function (IdealNotificationType) {
  IdealNotificationType["Banner"] = "BANNER";
  IdealNotificationType["Product"] = "PRODUCT";
  IdealNotificationType["Normal"] = "NORMAL";
})(IdealNotificationType || (exports.IdealNotificationType = IdealNotificationType = {}));
var IdealNotificationTypeTrans = exports.IdealNotificationTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, IdealNotificationType.Normal, "Mở app"), IdealNotificationType.Banner, "Banner"), IdealNotificationType.Product, "Khuyến mãi");