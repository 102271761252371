"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CvSubmitStatus = exports.CvSampleType = exports.CvOptionsTypeTrans = exports.CvOptionsType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var CvSampleType;
(function (CvSampleType) {
  CvSampleType["CV"] = "CV";
  CvSampleType["Letter"] = "LETTER";
  CvSampleType["Resume"] = "RESUME";
})(CvSampleType || (exports.CvSampleType = CvSampleType = {}));
var CvSubmitStatus;
(function (CvSubmitStatus) {
  CvSubmitStatus["Pending"] = "PENDING";
  CvSubmitStatus["Complete"] = "COMPLETE";
  CvSubmitStatus["Cancel"] = "CANCEL";
})(CvSubmitStatus || (exports.CvSubmitStatus = CvSubmitStatus = {}));
var CvOptionsType;
(function (CvOptionsType) {
  CvOptionsType["Experience"] = "experience";
  CvOptionsType["Education"] = "education";
  CvOptionsType["Certificate"] = "certificate";
  CvOptionsType["Activity"] = "activity";
  CvOptionsType["Skill"] = "skill";
})(CvOptionsType || (exports.CvOptionsType = CvOptionsType = {}));
var CvOptionsTypeTrans = exports.CvOptionsTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, CvOptionsType.Experience, 'Kinh nghiệm làm việc'), CvOptionsType.Education, 'Học vấn'), CvOptionsType.Certificate, 'Chứng chỉ'), CvOptionsType.Activity, 'Hoạt động'), CvOptionsType.Skill, 'Các kỹ năng');