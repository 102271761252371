"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var format = _interopRequireWildcard(require("@/utils/format"));
var config = _interopRequireWildcard(require("@/api/config"));
var contentDefine = _interopRequireWildcard(require("@/api/content-define"));
var _enum = require("@/utils/enum");
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage.vue"));
var _configuration = require("@/types/configuration");
var _ConfigModal = _interopRequireDefault(require("./components/ConfigModal.vue"));
var _ContentDefineModal = _interopRequireDefault(require("./components/ContentDefineModal.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Tinymce: _Tinymce.default,
    SingleImage: _SingleImage.default,
    ConfigModal: _ConfigModal.default,
    ContentDefineModal: _ContentDefineModal.default
  },
  data: function data() {
    return {
      configSystem: process.env.VUE_APP_CONFIG_SYSTEM,
      CONFIG_PARAMETER: _enum.CONFIG_PARAMETER,
      CONTENT_DEFINE_PARAMETER: _enum.CONTENT_DEFINE_PARAMETER,
      ConfigurationType: _configuration.ConfigurationType,
      formData: {},
      isLoading: false,
      loadingButton: false,
      tip: {},
      dialogStatus: "",
      dialogVisible: false,
      rules: {
        value: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nội dung"
        }
      },
      tableData: [],
      tableDataContent: [],
      type: "" //'content' | 'config',
    };
  },
  created: function created() {
    this.getData();
    this.getDataContentDefine();
  },
  methods: {
    handleInitTinymce: function handleInitTinymce() {
      this.$refs["editor"].setContent(this.formData.body);
    },
    handleEdit: function handleEdit(item) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.type = "config";
      this.formData = (0, _objectSpread2.default)({}, item);
      this.$refs.ConfigModal.handleUpdate(item);
    },
    handleEditContent: function handleEditContent(row) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.type = "content";
      // this.$nextTick(() => {
      //   this.$refs["form"].clearValidate();
      //   this.formData = { ...row };
      //   if (this.$refs["editor"]) {
      //     this.$refs["editor"].setContent(this.formData.body);
      //   }
      // });
      this.$refs.ContentDefineModal.handleUpdate(row);
    },
    handleAdd: function handleAdd() {
      this.$refs.ConfigModal.handleCreate();
    },
    handleAddConentDefine: function handleAddConentDefine() {
      this.$refs.ContentDefineModal.handleCreate();
    },
    getData: function getData() {
      var _this = this;
      this.isLoading = true;
      config.findAll().then(function (res) {
        _this.tableData = res.data.configurations;
      }).finally(function () {
        _this.isLoading = false;
      });
    },
    getDataContentDefine: function getDataContentDefine() {
      var _this2 = this;
      this.isLoading = true;
      contentDefine.findAll().then(function (res) {
        _this2.tableDataContent = res.data;
      }).finally(function () {
        _this2.isLoading = false;
      });
    }
  }
};