"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScholarshipTypeTrans = exports.ScholarshipType = exports.ScholarshipConditionTypeTrans = exports.ScholarshipConditionType = exports.ScholarshipBannerLayoutTrans = exports.ScholarshipBannerLayout = exports.ScholarshipBannerContentTypeTrans = exports.ScholarshipBannerContentType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ScholarshipConditionType;
(function (ScholarshipConditionType) {
  ScholarshipConditionType["Select"] = "SELECT";
  ScholarshipConditionType["Text"] = "TEXT";
  ScholarshipConditionType["Number"] = "NUMBER";
})(ScholarshipConditionType || (exports.ScholarshipConditionType = ScholarshipConditionType = {}));
var ScholarshipConditionTypeTrans = exports.ScholarshipConditionTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ScholarshipConditionType.Select, 'Chọn'), ScholarshipConditionType.Text, 'Nhập chữ'), ScholarshipConditionType.Number, 'Nhập số');
var ScholarshipBannerContentType;
(function (ScholarshipBannerContentType) {
  ScholarshipBannerContentType["None"] = "NONE";
})(ScholarshipBannerContentType || (exports.ScholarshipBannerContentType = ScholarshipBannerContentType = {}));
var ScholarshipType;
(function (ScholarshipType) {
  ScholarshipType["ScholarShip"] = "SCHOLARSHIP";
  ScholarshipType["Study"] = "STUDY";
})(ScholarshipType || (exports.ScholarshipType = ScholarshipType = {}));
var ScholarshipTypeTrans = exports.ScholarshipTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, ScholarshipType.ScholarShip, 'Học bổng'), ScholarshipType.Study, 'Khuyến học/Thiện nguyện');
var ScholarshipBannerContentTypeTrans = exports.ScholarshipBannerContentTypeTrans = (0, _defineProperty2.default)({}, ScholarshipBannerContentType.None, {
  label: "Mặc định",
  value: ScholarshipBannerContentType.None
});
var ScholarshipBannerLayout;
(function (ScholarshipBannerLayout) {
  ScholarshipBannerLayout["Top"] = "TOP";
  ScholarshipBannerLayout["Mid"] = "MID";
  ScholarshipBannerLayout["Bottom"] = "BOTTOM";
})(ScholarshipBannerLayout || (exports.ScholarshipBannerLayout = ScholarshipBannerLayout = {}));
var ScholarshipBannerLayoutTrans = exports.ScholarshipBannerLayoutTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ScholarshipBannerLayout.Top, 'Đầu trang'), ScholarshipBannerLayout.Mid, 'Giữa trang'), ScholarshipBannerLayout.Bottom, 'Cuối trang');