"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompanyTransactionTypeTrans = exports.CompanyTransactionType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var CompanyTransactionType;
(function (CompanyTransactionType) {
  CompanyTransactionType["Order"] = "ORDER";
  CompanyTransactionType["OrderCredit"] = "ORDER_CREDIT";
  CompanyTransactionType["Deposit"] = "DEPOSIT";
  CompanyTransactionType["Withdraw"] = "WITHDRAW";
  CompanyTransactionType["WithdrawCredit"] = "WITHDRAW_CREDIT";
  CompanyTransactionType["DepositCredit"] = "DEPOSIT_CREDIT";
})(CompanyTransactionType || (exports.CompanyTransactionType = CompanyTransactionType = {}));
var CompanyTransactionTypeTrans = exports.CompanyTransactionTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, CompanyTransactionType.Deposit, 'Nạp tủ'), CompanyTransactionType.Withdraw, 'Rút tủ'), CompanyTransactionType.Order, 'Đơn hàng'), CompanyTransactionType.OrderCredit, 'Đơn hàng (Tủ tín dụng)'), CompanyTransactionType.WithdrawCredit, 'Rút tủ tín dụng'), CompanyTransactionType.DepositCredit, 'Nạp tủ tín dụng');