"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clone = clone;
exports.cloneCityToDistrict = cloneCityToDistrict;
exports.create = create;
exports.findAllCity = findAllCity;
exports.findCity = findCity;
exports.findDeliveryVehicleCity = findDeliveryVehicleCity;
exports.findDistrict = findDistrict;
exports.findVehicleDistrict = findVehicleDistrict;
exports.updateVehicleCity = updateVehicleCity;
exports.updateVehicleDistrict = updateVehicleDistrict;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAllCity() {
  return (0, _request.default)({
    url: "/city"
  });
}
function findDistrict(cityCode) {
  return (0, _request.default)({
    url: "/city/".concat(cityCode, "/district")
  });
}
function findCity(params) {
  return (0, _request.default)({
    url: "/vehicleCity/city",
    method: "get",
    params: params
  });
}
function findVehicleDistrict(params) {
  return (0, _request.default)({
    url: "/vehicleCity/district",
    method: "get",
    params: params
  });
}
function findDeliveryVehicleCity(_ref) {
  var district = _ref.district,
    city = _ref.city;
  return (0, _request.default)({
    url: "/vehicleCity/delivery",
    method: "get",
    params: {
      district: district,
      city: city
    }
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/vehicleCity",
    method: "post",
    data: data
  });
}
function updateVehicleCity(data) {
  return (0, _request.default)({
    url: "/vehicleCity/city",
    method: "post",
    data: data
  });
}
function clone(data) {
  return (0, _request.default)({
    url: "/vehicleCity/clone/city",
    method: "post",
    data: data
  });
}
function cloneCityToDistrict(data) {
  return (0, _request.default)({
    url: "/vehicleCity/clone/district",
    method: "post",
    data: data
  });
}
function updateVehicleDistrict(data) {
  return (0, _request.default)({
    url: "/vehicleCity/district",
    method: "post",
    data: data
  });
}