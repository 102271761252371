"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.customerApi = void 0;
exports.getList = getList;
exports.getOrder = getOrder;
exports.getRef = getRef;
exports.resetPassword = resetPassword;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
function getList(listQuery) {
  return (0, _request.default)({
    url: "/customers",
    method: "get",
    params: listQuery
  });
}
function update(customerId, data) {
  return (0, _request.default)({
    url: "/customers/".concat(customerId, "/update"),
    method: "post",
    data: data
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/customers/signup",
    data: data,
    method: "post"
  });
}
function getOrder(customerId, params) {
  return (0, _request.default)({
    url: "/customers/".concat(customerId, "/orders"),
    params: params
  });
}
function getRef(customerId, params) {
  return (0, _request.default)({
    url: "/customers/".concat(customerId, "/ref"),
    params: params
  });
}
function getTransaction(customerId, params) {
  return (0, _request.default)({
    url: "/customers/".concat(customerId, "/transaction"),
    params: params
  });
}
function resetPassword(customerId, data) {
  return (0, _request.default)({
    url: "/customers/".concat(customerId, "/resetPassword"),
    method: "POST",
    data: data
  });
}
var customerApi = exports.customerApi = {
  getList: getList,
  fetchOne: function fetchOne(customerId) {
    return (0, _request.default)({
      url: "/customers/".concat(customerId)
    });
  },
  update: update,
  getOrder: getOrder,
  getRef: getRef,
  getTransaction: getTransaction,
  updateRefCode: function updateRefCode(customerId, data) {
    return (0, _request.default)({
      url: "/customers/".concat(customerId, "/update/code"),
      data: data,
      method: "post"
    });
  },
  create: create,
  voucher: function voucher(customerId, params) {
    return (0, _request.default)({
      url: "/customers/".concat(customerId, "/promotions"),
      params: params
    });
  },
  resetPassword: resetPassword
};