"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderTypeTrans = exports.orderTagOptions = exports.IdealOrderStatusTrans = exports.IdealOrderStatus = exports.CarrierTypeTrans = exports.CarrierTypeOrderLogging = exports.CarrierType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _IdealOrderStatusTran;
var IdealOrderStatus;
(function (IdealOrderStatus) {
  IdealOrderStatus["Pending"] = "PENDING";
  IdealOrderStatus["CustomerCancel"] = "CUSTOMER_CANCEL";
  IdealOrderStatus["AdminCancel"] = "ADMIN_CANCEL";
  IdealOrderStatus["Confirm"] = "CONFIRM";
  IdealOrderStatus["Delivering"] = "DELIVERING";
  IdealOrderStatus["PickUp"] = "PICK_UP";
  IdealOrderStatus["Complete"] = "COMPLETE";
  IdealOrderStatus["DeliveryComplete"] = "DELIVERY_COMPLETE";
  IdealOrderStatus["Refund"] = "REFUND";
  IdealOrderStatus["PendingPayment"] = "PENDING_PAYMENT";
  IdealOrderStatus["Delay"] = "DELAY";
  IdealOrderStatus["Stuck"] = "STUCK";
})(IdealOrderStatus || (exports.IdealOrderStatus = IdealOrderStatus = {}));
var IdealOrderStatusTrans = exports.IdealOrderStatusTrans = (_IdealOrderStatusTran = {}, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_IdealOrderStatusTran, IdealOrderStatus.Pending, 'Đơn mới'), IdealOrderStatus.CustomerCancel, 'Khách hủy'), IdealOrderStatus.AdminCancel, 'Quản trị viên hủy'), IdealOrderStatus.Delivering, 'Shipping'), IdealOrderStatus.PickUp, 'Shipping'), IdealOrderStatus.Complete, 'Hoàn tất'), IdealOrderStatus.Refund, 'Hoàn trả'), IdealOrderStatus.Confirm, 'Giao hàng'), IdealOrderStatus.DeliveryComplete, 'Giao hàng hoàn tất'), IdealOrderStatus.PendingPayment, 'Chờ thanh toán'), (0, _defineProperty2.default)((0, _defineProperty2.default)(_IdealOrderStatusTran, IdealOrderStatus.Delay, 'Deplay giao hàng'), IdealOrderStatus.Stuck, 'Không giao được hàng'));
var CarrierType;
(function (CarrierType) {
  CarrierType["GHTK"] = "GHTK";
  CarrierType["GHN"] = "GHN";
  CarrierType["TuThien"] = "TU_THIEN";
  CarrierType["Manual"] = "MANUAL";
})(CarrierType || (exports.CarrierType = CarrierType = {}));
var CarrierTypeOrderLogging = exports.CarrierTypeOrderLogging = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, CarrierType.GHTK, 'GHTK_EVENT'), CarrierType.GHN, 'GHN_EVENT');
var CarrierTypeTrans = exports.CarrierTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, CarrierType.GHN, 'Giao hàng nhanh'), CarrierType.GHTK, 'Giao hàng tiết kiệm'), CarrierType.TuThien, 'Từ thiện'), CarrierType.Manual, 'Nhận hàng tại công ty');
var orderTypeTrans = exports.orderTypeTrans = {
  'transport': 'Đơn gọi xe',
  'delivery': 'Đơn giao hàng',
  'food': 'Đơn giao đồ ăn',
  'mall': 'Đơn MALL',
  'ideal': 'Đơn bốc thăm'
};
var orderTagOptions = exports.orderTagOptions = {
  PENDING: {
    type: "",
    effect: "plain",
    color: ""
  },
  CUSTOMER_CANCEL: {
    type: "danger",
    effect: "plain",
    color: "red"
  },
  ADMIN_CANCEL: {
    type: "danger",
    effect: "plain",
    color: "yellow"
  },
  CONFIRM: {
    type: "",
    effect: "dark",
    color: "blue"
  },
  DELIVERING: {
    type: "",
    effect: "plain",
    color: "green"
  },
  PICK_UP: {
    type: "",
    effect: "plain",
    color: "green"
  },
  COMPLETE: {
    type: "success",
    effect: "plain",
    color: "green"
  },
  DELIVERY_COMPLETE: {
    type: "success",
    effect: "dark",
    color: "green"
  },
  REFUND: {
    type: "warning",
    effect: "dark",
    color: "yellow"
  },
  PENDING_PAYMENT: {
    type: "",
    effect: "dark",
    color: "yellow"
  },
  DELAY: {
    type: "danger",
    effect: "dark",
    color: "red"
  },
  STUCK: {
    type: "danger",
    effect: "dark",
    color: "red"
  }
};