"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _enum = require("@/utils/enum");
var _date = require("@/utils/date");
var driverApi = _interopRequireWildcard(require("@/api/driver"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var BalanceTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, _enum.BalanceType.Main, "Tủ chính"), _enum.BalanceType.Promotion, "Tủ khuyến mãi");
var _default = exports.default = {
  props: ["driverId"],
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      data: [],
      TYPE_TRANSACTION: _enum.TYPE_TRANSACTION,
      listQuery: {
        page: 1,
        limit: 50,
        balanceType: null,
        search: ""
      },
      total: 0,
      loading: false,
      BalanceTypeTrans: BalanceTypeTrans,
      date: [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")]
    };
  },
  watch: {
    driverId: {
      handler: function handler(val) {
        if (val) {
          this.resetQuery();
          this.fetchData();
        }
      },
      immediate: true
    }
  },
  methods: {
    resetQuery: function resetQuery() {
      this.listQuery = {
        page: 1,
        limit: 50,
        balanceType: null,
        search: ""
      };
      this.date = [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")];
    },
    formatDateTime: function formatDateTime(n) {
      return (0, _date.formatDateTime)(n);
    },
    fetchData: function fetchData() {
      var _this = this;
      this.listQuery.from = this.date[0];
      this.listQuery.to = this.date[1];
      this.loading = true;
      driverApi.transaction(this.driverId, this.listQuery).then(function (res) {
        _this.data = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.loading = false;
      });
    }
  }
};