"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.clearCustomerChatNoti = clearCustomerChatNoti;
exports.clearDriverChatNoti = clearDriverChatNoti;
exports.findOne = findOne;
exports.generateAccountantToken = generateAccountantToken;
exports.getInfo = getInfo;
exports.getList = getList;
exports.login = login;
exports.logout = logout;
exports.resetPassword = resetPassword;
exports.unsub = unsub;
exports.update = update;
exports.updatePassword = updatePassword;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(data) {
  return (0, _request.default)({
    url: "/auth/login",
    method: "post",
    data: data
  });
}
function generateAccountantToken() {
  return (0, _request.default)({
    url: "auth/generate-accountant-token",
    method: "post"
  });
}
function getInfo() {
  var oneSignalId = localStorage.getItem("OneSignalId");
  return (0, _request.default)({
    url: "/auth/profile",
    method: "get",
    params: {
      oneSignalId: oneSignalId
    }
  });
}
function findOne(userId) {
  return (0, _request.default)({
    url: "/staff/".concat(userId),
    method: "get"
  });
}
function updatePassword(data) {
  return (0, _request.default)({
    url: "/auth/profile/password/update",
    method: "post",
    data: data
  });
}
function resetPassword(id, data) {
  return (0, _request.default)({
    url: "/staff/".concat(id, "/password/reset"),
    method: "post",
    data: data
  });
}
function getList(listQuery) {
  return (0, _request.default)({
    url: "/staff",
    method: "get",
    params: listQuery
  });
}
function add(data) {
  return (0, _request.default)({
    url: "/staff",
    method: "post",
    data: data
  });
}
function update(userId, data) {
  return (0, _request.default)({
    url: "/staff/".concat(userId, "/update"),
    method: "post",
    data: data
  });
}
function logout() {
  return (0, _request.default)({
    url: "/user/logout",
    method: "post"
  });
}
function clearDriverChatNoti() {
  return (0, _request.default)({
    url: "/auth/clear/driver-chat-notification",
    method: "delete"
  });
}
function unsub() {
  return (0, _request.default)({
    url: "/auth/unSub",
    method: "delete"
  });
}
function clearCustomerChatNoti() {
  return (0, _request.default)({
    url: "/auth/clear/customer-chat-notification",
    method: "delete"
  });
}