"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MeetObjectTypeTrans = exports.MeetObjectType = exports.MeetConnectStatus = exports.MeetConfigParamsTrans = exports.MeetConfigParam = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MeetObjectType;
(function (MeetObjectType) {
  MeetObjectType["Product"] = "PRODUCT";
  MeetObjectType["Store"] = "STORE";
  MeetObjectType["Person"] = "PERSON";
})(MeetObjectType || (exports.MeetObjectType = MeetObjectType = {}));
var MeetObjectTypeTrans = exports.MeetObjectTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MeetObjectType.Person, 'Người'), MeetObjectType.Product, 'Vật phẩm'), MeetObjectType.Store, 'Cửa hàng');
var MeetConnectStatus;
(function (MeetConnectStatus) {
  MeetConnectStatus["Pending"] = "PENDING";
  MeetConnectStatus["Complete"] = "COMPLETE";
})(MeetConnectStatus || (exports.MeetConnectStatus = MeetConnectStatus = {}));
var MeetConfigParam;
(function (MeetConfigParam) {
  MeetConfigParam["PointWhenMeetPerson"] = "POINT_WHEN_MEET_PERSON";
})(MeetConfigParam || (exports.MeetConfigParam = MeetConfigParam = {}));
var MeetConfigParamsTrans = exports.MeetConfigParamsTrans = (0, _defineProperty2.default)({}, MeetConfigParam.PointWhenMeetPerson, 'Điểm khi kết nối với người');