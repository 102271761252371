"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProviderType = exports.ProviderStatusTrans = exports.ProviderStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ProviderType;
(function (ProviderType) {
  ProviderType["Agent"] = "AGENT";
})(ProviderType || (exports.ProviderType = ProviderType = {}));
var ProviderStatus;
(function (ProviderStatus) {
  ProviderStatus["Pending"] = "PENDING";
  ProviderStatus["Approve"] = "APPROVE";
  ProviderStatus["Reject"] = "REJECT";
})(ProviderStatus || (exports.ProviderStatus = ProviderStatus = {}));
var ProviderStatusTrans = exports.ProviderStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ProviderStatus.Approve, {
  value: ProviderStatus.Approve,
  label: "Đã duyệt",
  color: "success"
}), ProviderStatus.Reject, {
  value: ProviderStatus.Reject,
  label: "Từ chối",
  color: "danger"
}), ProviderStatus.Pending, {
  value: ProviderStatus.Pending,
  label: "Chưa duyệt",
  color: "normal"
});