"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reportBTCApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var reportBTCApi = exports.reportBTCApi = {
  report: function report(params) {
    return (0, _request.default)({
      url: '/reportCongThuong/report',
      params: params
    });
  },
  findAll: function findAll() {
    return (0, _request.default)({
      url: '/reportCongThuong'
    });
  },
  updateData: function updateData(id, data) {
    return (0, _request.default)({
      url: '/reportCongThuong/' + id,
      data: data,
      method: 'patch'
    });
  }
};