"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _format = require("@/utils/format");
//
//
//
//
//
//
//

require("echarts/theme/macarons"); // echarts theme

var animationDuration = 6000;
var _default = exports.default = {
  mixins: [_resize.default],
  props: {
    dataChart: Array,
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    series: Array
  },
  data: function data() {
    return {
      chart: null
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  mounted: function mounted() {
    // this.initChart();
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, "macarons");
      this.chart.setOption({
        legend: {},
        dataset: {
          source: this.dataChart
        },
        tooltip: {},
        series: [{
          width: "500px",
          height: "500px",
          type: "pie",
          radius: 100,
          center: ["50%", "50%"],
          tooltip: {
            formatter: "{c} %"
          }
          // No encode specified, by default, it is '2012'.
        }]
      });
    }
  }
};