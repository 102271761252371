import { render, staticRenderFns } from "./PopupTable.vue?vue&type=template&id=66cdd4c2&scoped=true"
import script from "./PopupTable.vue?vue&type=script&lang=ts"
export * from "./PopupTable.vue?vue&type=script&lang=ts"
import style0 from "./PopupTable.vue?vue&type=style&index=0&id=66cdd4c2&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66cdd4c2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\028fclass_admintool\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('66cdd4c2')) {
      api.createRecord('66cdd4c2', component.options)
    } else {
      api.reload('66cdd4c2', component.options)
    }
    module.hot.accept("./PopupTable.vue?vue&type=template&id=66cdd4c2&scoped=true", function () {
      api.rerender('66cdd4c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Popup/PopupTable.vue"
export default component.exports