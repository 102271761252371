import { render, staticRenderFns } from "./MallOrder.vue?vue&type=template&id=64c82edf"
import script from "./MallOrder.vue?vue&type=script&lang=ts"
export * from "./MallOrder.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Admin\\Documents\\028fclass_admintool\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64c82edf')) {
      api.createRecord('64c82edf', component.options)
    } else {
      api.reload('64c82edf', component.options)
    }
    module.hot.accept("./MallOrder.vue?vue&type=template&id=64c82edf", function () {
      api.rerender('64c82edf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/accountant/MallOrder.vue"
export default component.exports