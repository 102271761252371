"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("codemirror/addon/lint/lint.css");
require("codemirror/lib/codemirror.css");
require("codemirror/theme/rubyblue.css");
require("codemirror/mode/javascript/javascript");
require("codemirror/addon/lint/lint");
require("codemirror/addon/lint/json-lint");
var _idealNotificationSchedule = require("@/api/idealNotificationSchedule.api");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

require("script-loader!jsonlint");
var _default = exports.default = {
  // name: "JsonEditor",
  /* eslint-disable vue/require-prop-types */
  props: ["idealProduct"],
  data: function data() {},
  watch: {},
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    checkIsSoldComplete: function checkIsSoldComplete(row) {
      return row.pending + row.sold == row.quantity && row.quantity > 0;
    },
    checkProductExpired: function checkProductExpired(row) {
      //Check sản phẩm nolimit hết hạn
      return row.quantity == 0 && row.maxDrawDateAt > (0, _moment.default)().unix();
    }
  }
};