"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductStatusTrans = exports.ProductStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/defineProperty.js"));
var ProductStatus;
(function (ProductStatus) {
  ProductStatus["Pending"] = "PENDING";
  ProductStatus["Approve"] = "APPROVE";
  ProductStatus["Reject"] = "REJECT";
})(ProductStatus || (exports.ProductStatus = ProductStatus = {}));
var ProductStatusTrans = exports.ProductStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ProductStatus.Approve, {
  value: ProductStatus.Approve,
  label: "Đã duyệt",
  color: "success"
}), ProductStatus.Reject, {
  value: ProductStatus.Reject,
  label: "Từ chối",
  color: "danger"
}), ProductStatus.Pending, {
  value: ProductStatus.Pending,
  label: "Chưa duyệt",
  color: "normal"
});