"use strict";

var _interopRequireWildcard = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/028fclass_admintool/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.object.to-string.js");
var format = _interopRequireWildcard(require("@/utils/format"));
var tip = _interopRequireWildcard(require("@/api/tip"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isLoading: false,
      loadingButton: false,
      tip: {},
      dialogStatus: "",
      dialogVisible: false,
      rulesServiceGroup: {
        amount: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập số tiền"
        }
      },
      tipData: []
    };
  },
  computed: {},
  created: function created() {
    this.getTipData();
  },
  methods: {
    handleDelete: function handleDelete() {
      var _this = this;
      this.$confirm("Tip này sẽ bị xóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(function () {
        tip.deleteTip(_this.tip.id).then(function () {
          _this.$message({
            type: "success",
            message: "Xóa tip thành công"
          });
          _this.dialogVisible = false;
          _this.getTipData();
        });
      });
    },
    editData: function editData() {
      var _this2 = this;
      if (!this.tip.amount) {
        return this.$message({
          type: "error",
          message: "Vui lòng nhập số tiền"
        });
      }
      this.loadingButton = true;
      var data = {
        tip: {
          amount: this.tip.amount
        }
      };
      var tipId = this.tip.id;
      tip.update(tipId, data).then(function () {
        _this2.$message({
          type: "success",
          message: "Cập nhật tip thành công"
        });
        _this2.dialogVisible = false;
        _this2.getTipData();
      }).finally(function () {
        _this2.loadingButton = false;
      });
    },
    handleEdit: function handleEdit(item) {
      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.tip = (0, _objectSpread2.default)({}, item);
    },
    createData: function createData() {
      var _this3 = this;
      if (!this.tip.amount) {
        return this.$message({
          type: "error",
          message: "Vui lòng nhập số tiền"
        });
      }
      this.loadingButton = true;
      var data = {
        tip: this.tip
      };
      tip.create(data).then(function (res) {
        _this3.$message({
          type: "success",
          message: "Tạo tip thành công"
        });
        _this3.dialogVisible = false;
        _this3.getTipData();
      }).finally(function () {
        _this3.loadingButton = false;
      });
    },
    handleAdd: function handleAdd() {
      this.dialogStatus = "add";
      this.dialogVisible = true;
      this.tip = {};
    },
    formatVND: function formatVND(n) {
      return format.formatCurrency(n);
    },
    getTipData: function getTipData() {
      var _this4 = this;
      this.isLoading = true;
      tip.get().then(function (res) {
        _this4.tipData = res.data;
      }).finally(function () {
        _this4.isLoading = false;
      });
    }
  }
};